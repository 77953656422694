.UserControlPage-item {
    td {
        height: 2.5rem;
    }
    .status-icon {
        font-size: 1.6rem;
        color: black;
    }

    .max-width-200px {
        max-width: 200px;
    }

    .max-width-120px {
        max-width: 120px;
    }

    .max-width-50px {
        width: 50px;
    }
}
