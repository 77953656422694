.w-20 {
    width: 20%;
}

.detection-row {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;

    &__title {
        font-size: 1rem;
        font-weight: 700;
    }

    &__subtitle {
        font-weight: 700;
        font-size: 13px;
        white-space: nowrap;
    }
    &__image {
        width: 300px;
    }

    &__first-col > * {
        flex-basis: 0;
        flex-grow: 1;
    }

    &__info {
        font-size: 12px;
        padding-left: 5px;
    }

    &__first-col {
        width: 41%;

        + div {
            width: 59%;
        }
    }
}

.case-info {
    font-size: 0.825rem;
    font-style: italic;
    color: #319edb;
}
