html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f2f1f1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.rmsc {
    --rmsc-h: calc(1.5em + (0.5rem + 2px)) !important;
    min-height: var(--rmsc-h);
    padding: 0.25rem 0;
    font-size: 0.875rem;
    border-radius: 0.2rem;

    &.status-filter {
        & .dropdown-content {
            width: 100%;
        }
    }

    & .dropdown-content {
        z-index: 2 !important;
        width: 300px;
        box-shadow: 0px 5px 5px #cacaca;
    }
}

.my-popover {
    min-width: 100px;
    max-width: 600px;
    min-height: 100px;
    max-height: 300px;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.08);

    &__title {
        background-color: #c4c4c4;
        color: #5f5f5f;
        padding: 10px 0 10px 16px;
        font-size: 1.2rem;
        font-weight: 700;
    }
}

.case-detail {
    &__list {
        padding: 20px 40px;
    }

    &__item {
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 20px;

        & > div {
            font-size: 0.9rem;
            font-weight: 400;
            color: #9b9b9b;
        }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.width-172 {
    width: 172px;
}

.color-505050 {
    color: #505050;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
}
