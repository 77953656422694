.UserControlPage-filter {
    .input-control {
        box-shadow: 0px 4px 4px #cacaca;
        &:focus {
            border-color: #4285f4;
        }
        &::placeholder {
            color: #aaa;
        }
    }

    .rmsc {
        & .dropdown-container {
            box-shadow: 0px 5px 5px #cacaca;
        }
        & .item-renderer {
            display: flex;
            align-items: center;
            max-width: 125px;
            & span {
                &:hover {
                    overflow: visible;
                    white-space: normal;
                    width: auto;
                }
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .close-icon {
        position: absolute;
        top: 55%;
        right: 0;
        transform: translate(-50%, -50%);
        font-size: 2rem;
        font-weight: 400;
        color: rgba($color: #aaa, $alpha: 1);
        cursor: pointer;
    }
}
