.warning-modal {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
    }
}
