@import 'bootstrap/scss/bootstrap-utilities.scss';

.AdminPanelPage {
    height: auto;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    .card {
        height: 100%;
    }

    .admin-panel-wrapper {
        height: 100%;
        overflow-y: hidden;
    }
    .sidebar {
        width: 12%;
        border-right: 2px solid lightgray;
    }
    .sidebar-content {
        width: 88%;
    }
}

@include media-breakpoint-up(xs) {
    .AdminPanelPage {
        .sidebar {
            width: 100%;
        }
        .sidebar-content {
            width: 100%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .AdminPanelPage {
        .sidebar {
            width: 12%;
        }
        .sidebar-content {
            width: 88%;
        }
    }
}
