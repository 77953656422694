.image-bar {
    & .camera-one {
        position: relative;
        color: #092967;
        & i {
            font-size: 30px;
            display: inline-block;
            width: 40px;
            height: 40px;
        }

        & span {
            position: absolute;
            top: 14px;
            left: 7px;
            font-size: 0.7rem;
        }
    }

    & .camera-two {
        position: relative;
        color: #d67605;
        padding-right: 20px;
        & i {
            display: inline-block;
            width: 30px;
            height: 30px;
            transform: rotateY(-180deg);
            font-size: 30px;
        }

        & span {
            position: absolute;
            top: 14px;
            left: 16px;
            font-size: 0.7rem;
        }
    }

    & .case-info {
        font-size: 0.825rem;
        font-style: italic;
        color: #319edb;
        left: 50%;
        transform: translateX(-50%);
    }

    & .case-inspected {
        font-size: 0.825rem;
    }

    & .dragdrop {
        margin-left: 39px;
        top: 0;
        left: 0;
        width: 40px;
        height: 45px;
        border: 2px solid #000;
        &::before {
            content: '';
            position: absolute;
            margin-top: -2px;
            top: 0;
            left: 50%;
            width: 2px;
            height: 45px;
            background-color: #000;
        }
    }

    & .marker {
        border: 1px solid #000;
        border-bottom: none;
    }
}

.itemTimeLine {
    color: white;
}
