@import 'bootstrap/scss/bootstrap-utilities.scss';
.ReactModal__Content {
    width: 95vw;
    height: auto;
    .role-error {
        color: red;
    }
    .check-message {
        &--success {
            color: green;
        }
        &--error {
            color: red;
        }
    }
}

@include media-breakpoint-up(md) {
    .ReactModal__Content {
        width: 70vw;
    }
}

@include media-breakpoint-up(lg) {
    .ReactModal__Content {
        width: 50vw;
    }
}
@include media-breakpoint-up(xl) {
    .ReactModal__Content {
        width: 40vw;
    }
}
@include media-breakpoint-up(xxl) {
    .ReactModal__Content {
        width: 30vw;
    }
}
