@import 'bootstrap/scss/bootstrap-utilities.scss';

.Footer {
    font-size: 0.875rem;
    background-color: #fff;
    .info {
        flex-direction: column;
        width: fit-content;
        &--right {
            align-items: flex-end;
        }
    }

    &__content {
        flex-direction: column;

        a {
            color: #212529;
            text-decoration: none;
        }

        > div {
            min-width: 265px;
        }

        @media screen and (min-width: 768px) {
            flex-direction: row;
            padding-left: 0;
            padding-right: 0;
            span {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        div > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            @media screen and (min-width: 768px) {
                justify-content: center;
            }

            img {
                max-width: 150px;
                max-height: 68px;
            }
        }

        div:last-child > div {
            justify-content: flex-start;
        }
    }

    @include media-breakpoint-up(xs) {
        .info {
            &--right {
                align-items: flex-start;
            }
        }
    }
    @include media-breakpoint-up(sm) {
        .info {
            &--right {
                align-items: flex-end;
            }
        }
    }
}
