.image-slider {
    & .row {
        --bs-gutter-x: 0.8rem;
        margin: 0 30px;
        & > .image__col {
            flex: 1 1 12.5%;
            max-width: 12.5%;
        }

        & > .image-central__col {
            flex: 1 1 25%;
            max-width: 25%;
        }
    }

    & .card-disabled {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #ffffff, $alpha: 0.7);
    }

    & & {
        &__percent {
            font-size: 0.8125rem;
            font-weight: 700;
        }

        &__slider-control {
            top: 50%;
            left: 0;
            font-size: 4rem;
        }

        &__validate-control {
            top: 70%;
            left: 0;
        }

        &__prev-button {
            left: calc(-1 * 2.6rem);
            transition: all ease-in-out 0.3s;
            &:hover {
                color: rgba($color: #000000, $alpha: 0.3);
            }

            &.disabled {
                color: rgba($color: #000000, $alpha: 0.3);
                pointer-events: none;
            }
        }

        &__next-button {
            right: calc(-1 * 2.6rem);
            transition: all ease-in-out 0.3s;
            &:hover {
                color: rgba($color: #000000, $alpha: 0.3);
            }

            &.disabled {
                color: rgba($color: #000000, $alpha: 0.3);
                pointer-events: none;
            }
        }

        &__decline-button {
            left: calc(-1 * 10rem);
            transition: all ease-in-out 0.3s;
            font-size: 2rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            &:hover {
                color: rgba($color: #000000, $alpha: 0.3);
            }

            &.disabled {
                color: rgba($color: #000000, $alpha: 0.3);
                pointer-events: none;
            }
        }

        &__confirm-button {
            right: calc(-1 * 10rem);
            transition: all ease-in-out 0.3s;
            font-size: 2.4rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            &:hover {
                color: rgba($color: #000000, $alpha: 0.3);
            }

            &.disabled {
                color: rgba($color: #000000, $alpha: 0.3);
                pointer-events: none;
            }
        }

        &__image {
            border-radius: 0.25rem;
            border-width: 2px;
            border-style: solid;
        }

        &__info {
            border-radius: 0.25rem;
            border-width: 2px;
            border-style: solid;
        }
    }
}
