.CaseOverviewPage-finding {
    &__image {
        margin-bottom: 8px;
    }
    & & {
        &__title {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 0;
        }

        &__close-icon {
            font-size: 1.8rem;
        }

        &__image-wrapper {
            font-size: 13px;
            margin-top: 10px;
        }

        &__image-score {
            color: #505050;
            width: auto;
            font-size: 13px;
            font-weight: bold;
            border-radius: 0.25rem;
        }
    }
}
