.image-mark {
    border-radius: 50%;
    border: 3px solid red;
    position: absolute;

    &__index {
        font-weight: 900;
        color: #ff0000;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);
        font-size: 16px;
    }

    @media (max-width: 1023px) {
        border-width: 2px;

        &__index {
            font-size: 14px;
        }
    }

    @media (max-width: 767px) {
        &__index {
            font-size: 12px;
        }
    }
}
