.loading-component {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #ffffff, $alpha: 1);
    z-index: 9999;
    display: flex;
}
