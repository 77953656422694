@import 'bootstrap/scss/bootstrap-utilities.scss';
.CaseListPage {
    &.table-fixed {
        overflow: auto;
        height: 50vh;

        & thead {
            & tr {
                color: #505050;
                font-size: 0.8125rem;
                font-weight: 700;
                & th {
                    position: sticky;
                    top: 0;
                    background-color: white;
                    z-index: 1;
                    border-bottom: none;
                    box-shadow: inset 0 -1px 0 #000000;
                }
            }
        }

        & tbody {
            & tr {
                color: #9b9b9b;
                font-size: 0.8125rem;
                font-weight: 400;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &.table-fixed {
            height: 56.5vh;
        }
    }

    @include media-breakpoint-up(lg) {
        &.table-fixed {
            height: 78.5vh;
        }
    }
    @include media-breakpoint-up(xl) {
        &.table-fixed {
            height: 53vh;
        }
    }
    @include media-breakpoint-up(xxl) {
        &.table-fixed {
            height: 73vh;
        }
    }
}
