.page-layout {
    display: flex;
    justify-content: space-around;
}

.background {
    background-image: url(../../assets/images/background.png);
    height: 100vh;
    background-size: cover;
    object-fit: cover;
    flex: 1;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content-logo {
    flex: 1;
    display: flex;
    margin-left: 70px;
    margin-top: 50px;
}
.logo {
    min-width: 300px;
    height: 77px;
}
.aispeedlogo {
    width: 200px;
    margin-left: -22px;
}
.content-info {
    flex: 3;
    display: flex;
    flex-direction: column;
    margin-left: 80px;
}
.content-copy-right {
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-left: 70px;
    margin-bottom: 2%;
}
@media screen and (max-width: 1024px) {
    .content-logo {
        margin-left: 13%;
    }
    .content-info {
        flex: 3 1;
        display: flex;
        flex-direction: column;
        margin-left: 13%;
    }
    .content-copy-right {
        flex: 1 1;
        display: flex;
        align-items: flex-start;
        margin-left: 13%;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 768px) {
    .content-logo {
        flex: 1;
        display: flex;
        align-items: flex-end;
        margin-left: 10%;
        margin-top: 10%;
    }
    .content-info {
        flex: 3 1;
        display: flex;
        flex-direction: column;
        margin-left: 10%;
    }
    .content-copy-right {
        flex: 1;
        display: flex;
        align-items: flex-start;
        margin-left: 10%;
        margin-top: 10%;
    }
}
