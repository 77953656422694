.mark-modal-content {
    height: calc(100% - 28px);
    & .left-panel {
        margin-right: 6px;
        padding-right: 0;
    }
    & .btn {
        box-shadow: 0px 6px 6px #979797;
    }
    & & {
        &__prev-button {
            position: absolute;
            top: 50%;
            left: 0;
            font-size: 36px;
            &.disabled {
                color: rgba($color: #000000, $alpha: 0.3);
                pointer-events: none;
            }
            &:hover {
                color: rgba($color: #000000, $alpha: 0.3);
            }
        }
        &__next-button {
            position: absolute;
            top: 50%;
            right: 0;
            font-size: 36px;
            &.disabled {
                color: rgba($color: #000000, $alpha: 0.3);
                pointer-events: none;
            }
            &:hover {
                color: rgba($color: #000000, $alpha: 0.3);
            }
        }
        &__close-modal {
            &:hover {
                color: #ff0000;
            }
        }
        &__overlap-box {
            cursor: copy;
            position: absolute;
            // background-color: rgba(0, 0, 0, 0.6);
            z-index: 1;
        }
    }
    & .right-panel {
        margin-left: 6px;
        margin-right: 10px;
        margin-bottom: 20px;
        background: #e5e5e5;
    }
    & .mark-box {
        height: 226px;
        display: flex;
        align-items: baseline;
        overflow-y: auto;
        overflow-x: hidden;
    }
    & .mark-box__content {
        width: 100%;
        display: flex;
        min-height: 226px;
        flex-direction: column;
        justify-content: flex-end;
    }
    & & {
        &__mark-item {
            background: #c4c4c4;
            border-radius: 12px;
            height: 56px;
            min-height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;
            font-weight: bold;
            font-size: 16px;
            margin-left: 0;
            width: 100%;
            position: relative;
            button {
                position: absolute;
                right: -40%;
                &:hover {
                    color: #ff0000;
                }
            }
        }
        &__footer {
            margin: 1.5rem;
            margin-bottom: 8px;
            width: calc(100% - 3rem);
            background: #ffffff;
            height: 76px;
            border: 1px solid #505050;
            color: #505050;
            font-weight: bold;
            font-size: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
