.CaseOverviewPage {
    &__content {
        padding-right: 0;
    }
    &__detection {
        padding-left: 10px;
    }

    // button {
    //     &.disabled {
    //         color: rgba($color: #000000, $alpha: 0.3);
    //         pointer-events: none;
    //     }
    // }

    & .button {
        &__text {
            font-size: 0.8125rem;
            font-weight: 700;
        }

        &__central {
            font-size: 50px;
            transition: transform ease-in-out 0.3s;
            &:hover {
                transform: translateY(-2px);
            }
        }

        &__complete {
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
    & .btn {
        box-shadow: 0px 6px 6px #979797;
    }

    & & {
        &__setting-icon {
            $background: #999;
            width: 40px;
            height: 40px;
            bottom: 8px;
            right: 8px;
            box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.3);
            background-color: rgba($color: $background, $alpha: 0.3);
            border-radius: 3px;
            transition: all 0.2s ease-in-out;
            &:hover {
                background-color: rgba($color: $background, $alpha: 0.18);
            }
        }
    }
}
