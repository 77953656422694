.CaseOverviewPage-detail {
    & .detail-list {
        list-style-type: circle;
        padding-left: 1rem;
        &__item {
            padding: 14px 14px 14px 0;
            font-size: 1rem;
            font-weight: 400;
            color: rgba($color: #000000, $alpha: 0.5);
        }
    }
    & &__title {
        font-size: 1rem;
        font-weight: 700;
    }
}
