.image-video .image-video__content {
    background-color: white;
    overflow-x: auto;
}
.text-cam-2 {
    color: orange;
    font-weight: bold;
}
.text-cam-1 {
    color: #0a2f77;
    font-weight: bold;
}
.image-video .image-video__content .title {
    font-weight: bold;
    color: rgb(92, 92, 92);
}

.image-size-icon {
    color: black;
    font-size: 1.5rem;
    -webkit-text-stroke: 1px;
    &--left {
        margin-right: 0.5rem;
        margin-left: 2rem;
    }
}
