.CaseSummaryPage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        min-width: 111.81px;
        font-size: 13px;
        font-weight: 700;
    }
}

.is-printing {
    .CaseSummaryPage-header {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    .hide-on-export {
        display: none;
    }

    .case-info {
        width: 100%;
    }

    .detection-list {
        margin-bottom: 50px;
    }

    .detection-row {
        margin-top: 10px !important;
    }
}

.summary-table-container {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    overflow-x: auto;
}

.summary-table {
    width: 650px;

    th,
    td {
        border: 2px solid #000;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    th {
        background-color: wheat;
    }

    tr > td:nth-child(2) {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .summary-table {
        width: unset;
    }
}
