.CaseListPage-item {
    & .status-icon {
        font-size: 1.6rem;
    }

    & .status-text {
        font-size: 0.8125rem;
        font-weight: 700;
    }

    & .max-width-200px {
        max-width: 200px;
    }

    & .max-width-100px {
        max-width: 100px;
    }

    & .max-width-120px {
        max-width: 120px;
    }
}
