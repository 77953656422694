.VideoAdministrationPage-item {
    .status-icon {
        font-size: 1.3rem;
        color: black;
    }

    .max-width-200px {
        max-width: 200px;
    }

    .max-width-120px {
        max-width: 120px;
    }
}
