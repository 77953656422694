.footer-button {
    display: flex;
    justify-content: center;

    .btn {
        width: 150px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 6px 6px #adadad;
    }
}
