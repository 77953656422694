@import 'bootstrap/scss/bootstrap-utilities.scss';
.SimilarImageModal.image-slider {
    .original-image {
        margin: 0.5rem 0;
        &__wrapper {
            border-right: 1px solid black;
        }
    }
    .img-checker {
        bottom: 0;
        right: 0;
        font-size: 2rem;
        color: white;
    }
    .image-wrapper {
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        max-height: 26vh;
        max-width: 100%;
    }
    & .row {
        --bs-gutter-x: 0.8rem;
        margin: 0;
        & > .image__col {
            flex: 1 1 20%;
            max-width: 20%;
            margin: 0.5rem 0;
        }

        & > .image-central__col {
            flex: 1 1 25%;
            max-width: 25%;
        }
    }

    & & {
        &__percent {
            font-size: 0.8125rem;
            font-weight: 700;
        }

        &__image {
            border-radius: 0.25rem;
            border-width: 2px;
            border-style: solid;
        }

        &__info {
            border-radius: 0.25rem;
            border-width: 2px;
            border-style: solid;
        }
    }
}

@include media-breakpoint-up(md) {
    .SimilarImageModal.image-slider {
        .original-image {
            margin: 3.9rem 0;
        }
        .image-wrapper {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            max-height: 34vh;
            max-width: 100%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .SimilarImageModal.image-slider {
        .original-image {
            margin: 1.95rem 0;
        }
        .image-wrapper {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            max-height: 29vh;
            max-width: 100%;
        }
    }
}
@include media-breakpoint-up(xl) {
    .SimilarImageModal.image-slider {
        .original-image {
            margin: 1.85rem 0;
        }
        .image-wrapper {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            max-height: 63vh;
            max-width: 100%;
        }
    }
}
@include media-breakpoint-up(xxl) {
    .SimilarImageModal.image-slider {
        .original-image {
            margin: 3.9rem 0;
        }
        .image-wrapper {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            max-height: 61vh;
            max-width: 100%;
        }
    }
}
