.btn-login {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 108px;
    height: 32px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #0067b8;
    color: aliceblue;
    background-color: #0067b8;
    cursor: pointer;
}
.btn-login:hover {
    background-color: #065897;
    color: aliceblue;
}
