@import 'bootstrap/scss/bootstrap-utilities.scss';

.SideBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nav-link {
        width: 100px;
        margin-bottom: 0.5rem;
    }
}

@include media-breakpoint-up(xs) {
    .SideBar {
        flex-direction: row;
        .nav-link {
            margin-right: 0.5rem;
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .SideBar {
        flex-direction: column;
        .nav-link {
            margin-right: 0;
            margin-bottom: 0.5rem;
        }
    }
}
