.navbar-logo {
    height: 50px;
    margin-right: 5%;
}
.app-nav .navbar {
    box-shadow: 0 2px 10px #888888;
    background-color: white;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
    width: 100%;
    padding: 0px;
    margin-top: 8px;
    cursor: pointer;
}
.navbar-light .navbar-nav .nav-link {
    cursor: pointer;
    margin-bottom: 2px;
}
.custom-toggle .dropdown-toggle::after {
    display: none;
}
.avatar {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    object-fit: cover;
}

.fullname {
    margin: 0 10px;
}
