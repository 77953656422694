.app-content {
    background-color: '#f2f1f1';
    height: '100vh';
}

.icon-disabled {
    opacity: 0.7;
    transition: unset;
    transform: unset;
    pointer-events: none;
    cursor: unset;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.mb-0 {
    margin-bottom: 0;
}
